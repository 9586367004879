
















































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  ActivityDto,
  AuditFlowScope,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  PagedResultDto,
} from "@/api/appService";
import { regionData, CodeToText } from "element-china-area-data";
import ProvinceCityAreaView, {
  ProvinceCityAreaTownDto,
} from "@/components/CustomForm/FormDetail/components/ProvinceCityAreaView.vue";
import ProvinceCityAreaContent from "@/components/CustomForm/TableDetail/components/ProvinceCityAreaContent.vue";
import { AuditBodyDto } from "@/utils/customDto";

@Component({
  name: "ActivityDetail",
  components: { ProvinceCityAreaContent, ProvinceCityAreaView },
})
export default class ActivityApplyList extends Vue {
  options: any = regionData;
  activityArea: ProvinceCityAreaTownDto = {
    provinceId: 0,
    cityId: 0,
    areaId: 0,
  };
  loading = true;
  detail: ActivityDto = {
    leader: {
      surname: ''
    },
    // activityTypeDictionary: {
    //   displayName: ""
    // }
  };
  activityId = 0;

  hasActivityPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }
  created() {
    if (this.$route.params.id) {
      this.activityId = Number(this.$route.params.id);
    }
    this.fetchData({ id: this.activityId });
  }
  // 获取表数据
  fetchData(params: any) {
    console.log("test:", params);
    this.loading = true;
    return api.activity.get(params).then((res) => {
      this.detail = res;
      this.activityArea = {
        provinceId: res!.provinceId ?? 0,
        cityId: res!.cityId ?? 0,
        areaId: res!.areaId ?? 0,
      };
      this.loading = false;
    });
  }

  // 编辑
  handleEdit(row: ActivityDto) {
    this.id = row.id!;
    this.$router.push({
      name: "ActivityEdit",
      params: { id: row.id! + "" },
    });
  }

  private cancel() {
    this.$router.back();
  }
}
